
import { chartColors } from './common'

export const rowDataToChartData = (
  dataRows,
  label = 'Data'
) => {
  const chartBGColors = chartColors.slice(0, dataRows.length)

  const pieChartData = {
    labels: dataRows.map(row => row.name),
    datasets: [
      {
        label: label,
        data: dataRows.map(row => row.views),
        backgroundColor: chartBGColors,
        borderColor: chartBGColors,
        borderWidth: 1,
      }
    ]
  }

  return pieChartData
}
