import React from 'react'
import useRawAnalyticsData from '../hooks/use-raw-analytics-data'
import { AnalyticsContext } from './context'
import Box from '@material-ui/core/Box'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import NoDataPlaceholder from './no-data-placeholder'
import Grid from '@material-ui/core/Grid'

const textToRemove = [
  'Agenda item bekeken: ',
  'Nieuwsitem bekeken: ',
  'Pagina bekeken: ',
  'Vacature bekeken: '
]

const removeExtraText = (text) => {
  let newText = text

  textToRemove.forEach(txt => {
    newText = newText.split(txt).pop()
  })

  return newText
}

const makeRowsData = (response) => {
  const rows = response?.data?.rows

  if (rows && rows.length) {
    const mappedRows = rows
      .map(row => {
        return {
          category: row[0],
          action: removeExtraText(row[1]),
          amount: row[3]
        }
      })

    const perActivity = [...new Set(mappedRows.map(row => row.category))].filter(row => row !== 'Bedrijfsprofiel')

    return {
      mappedRows,
      perActivity: perActivity
    }
  }

  return {}
}

const filterKeyConvertedToAnalyticsKeys = (filterKey) => {
  switch (filterKey) {
    case 'category':
      return {
        filter: 'ga:eventCategory',
      }
    case 'event':
      return {
        filter: 'ga:eventAction',
      }
  }
}

const makeFilter = (filters) => {
  const mappedFilters = filters.map((filterKey, index) => {
    const key = Object.keys(filterKey)
    const filterKeyConverted = filterKeyConvertedToAnalyticsKeys(key[0])
    const keyValue = filterKey[key[0]]

    if (index === (filters.length - 1)) {
      return `${filterKeyConverted?.filter}==${keyValue}`
    }

    return `${filterKeyConverted?.filter}==${keyValue}${filterKey.sep}`
  })

  return mappedFilters.join('')
}

const AgendaDataView = ({ selectedPartner, active }) => {
  const { currentDate } = React.useContext(AnalyticsContext)

  const stringFilter = makeFilter([
    { 'category': selectedPartner, sep: ';' },
    { 'event': 'Vacature', sep: ',' },
    { 'event': 'Pagina', sep: ',' },
    { 'event': 'Nieuws', sep: ',' },
    { 'event': 'Agenda', sep: ',' },
  ])

  const data = useRawAnalyticsData({
    filters: stringFilter,
    metrics: 'ga:totalEvents',
    dimensions: 'ga:eventAction,ga:eventLabel,ga:eventCategory',
    startData: currentDate
  })

  const { mappedRows: rowData, perActivity } = makeRowsData(data)

  if (!active) {
    return null
  }

  if (!data?.data?.rows) {
    return <Grid container>
      <NoDataPlaceholder />
    </Grid>
  }

  return <Box>
    {
      rowData && perActivity.map((activity, index) => {
        return <Table
          key={index}
          style={{
            width: '100%'
          }}
        >
          <TableHead>
            <TableRow>
              <TableCell>{activity}</TableCell>
              <TableCell align='right'>Weergaven</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              rowData
                .filter(data => data.category === activity)
                .map((act, index) => {
                  return <TableRow key={index}>
                    <TableCell>{act.action}</TableCell>
                    <TableCell align='right'>{act.amount}</TableCell>
                  </TableRow>
                })
            }
          </TableBody>
        </Table>
      })
    }
  </Box>
}

export default AgendaDataView




// ga:eventCategory==50661 - Camping & Bungalowpark In de Rimboe