import React from 'react'
import PropTypes from 'prop-types'
import ToggleButton from '@material-ui/lab/ToggleButton'
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup'
import TableChartIcon from '@material-ui/icons/TableChart'
import PieChartIcon from '@material-ui/icons/PieChart'

const ChartsViewSelector = ({ setNewView, currentView }) => {
  const handleOnChange = (event, newView) => {
    if (newView !== null) {
      setNewView(newView)
    }
  }
  return <div style={{
    marginBottom: 12
  }}>
      <ToggleButtonGroup
      value={currentView}
      exclusive
      onChange={handleOnChange}
      size='small'
    >
      <ToggleButton value='chart'>
        <PieChartIcon fontSize='small' />
      </ToggleButton>
      <ToggleButton value='table'>
        <TableChartIcon fontSize='small' />
      </ToggleButton>
    </ToggleButtonGroup>
  </div>
}

ChartsViewSelector.propTypes = {
  setNewView: PropTypes.func.isRequired,
  currentView: PropTypes.string.isRequired
}

export default ChartsViewSelector