import React from 'react'

import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

const NoDataPlaceholder = ({ text }) => {
  return <Grid container item xs={12}>
      <Box justify='center' display='flex'>
        <Typography variant='subtitle2'>
          {
            text
              ? text
              : 'Helaas, er is hier op dit moment geen data'
          }
        </Typography>
      </Box>
    </Grid>
}

export default NoDataPlaceholder
