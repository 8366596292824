import React from 'react'
import { DataGrid } from '@material-ui/data-grid'

const columns = [
  {
    field: 'name',
    headerName: 'Actie',
    flex: 1,
    filterable: false,
    sortable: false
  },
  {
    field: 'views',
    headerName: 'Views',
    flex: .5,
    filterable: false,
    sortable: false
  }
]

const DetailsDataGrid = ({ rows }) => {
  return <div style={{ height: 400, width: '100%' }}>
    <DataGrid
      rows={rows}
      columns={columns}
      pageSize={7}
      disableSelectionOnClick
      disableColumnFilter
      disableColumnMenu
      sortModel={[
        {
          field: 'views',
          sort: 'desc'
        }
      ]}
    />
  </div>
}

export default DetailsDataGrid