import React from "react";
import TextField from "@material-ui/core/TextField";
import StaticDateRangePicker from "@material-ui/lab/StaticDateRangePicker";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { format, subDays, subYears, startOfMonth, isEqual, subMonths} from "date-fns";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import LocalizationProvider from "@material-ui/lab/LocalizationProvider";
import {
  Box,
  Chip,
  Stack
} from "@material-ui/core";
import Popover from '@material-ui/core/Popover';
import { AnalyticsContext } from './context'

const predefinedRanges = [
  {
    label: 'Vandaag',
    date: [new Date().setHours(0,0,0,0), new Date().setHours(0,0,0,0)]
  },
  {
    label: 'Gisteren',
    date: [subDays(new Date(), 1), new Date()]
  },
  {
    label: 'Afgelopen week',
    date: [subDays(new Date().setHours(0,0,0,0), 7), new Date().setHours(0,0,0,0)]
  },
  {
    label: 'Afgelopen maand',
    date: [subMonths(startOfMonth(new Date().setHours(0,0,0,0)), 1), subMonths(new Date().setHours(0,0,0,0), 1)]
  },
  {
    label: 'Afgelopen 30 dagen',
    date: [subDays(new Date().setHours(0,0,0,0), 30), new Date().setHours(0,0,0,0)]
  },
  {
    label: 'Altijd',
    date: [subYears(new Date().setHours(0,0,0,0), 2), new Date().setHours(0,0,0,0)]
  }
]

const StaticDateRange = () => {
  const { currentDate, setCurrentDate } = React.useContext(AnalyticsContext)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment>
      <Box onClick={handleClick}
        sx={{
          border: '1px solid #e0e0e0',
          padding: '5px',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
      >
        <Stack
          direction="row"
          display="flex"
          alignItems="center"
        >
          {format(currentDate[0], "dd-MM-yyyy")} {" - "}
          {format(currentDate[1], "dd-MM-yyyy")}
          {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </Stack>
      </Box>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Stack
          sx={{
            padding: 3
          }}
        >
          <Box>
            {
              predefinedRanges.map((range, index) => {
                const isFirstDateEqual = isEqual(range.date[0], currentDate[0])
                const isSecondDateEqual = isEqual(range.date[1], currentDate[1])
                const isActive = isFirstDateEqual && isSecondDateEqual
                return <Chip
                  key={index}
                  sx={{
                    mr: 1
                  }}
                  size='small'
                  color={isActive ? 'primary' : 'default'}
                  onClick={() => setCurrentDate(range.date)}
                  label={range.label}
                />
              })
            }
          </Box>
          <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                displayStaticWrapperAs="desktop"
                value={currentDate}
                disableCloseOnSelect={false}
                disableFuture
                onChange={(newValue) => {
                  setCurrentDate(newValue);
                }}
                renderInput={(startProps, endProps) => (
                  <React.Fragment>
                    <TextField size="small" {...startProps} />
                    <Box sx={{ mx: 2 }}> tot </Box>
                    <TextField size="small" {...endProps} />
                  </React.Fragment>
                )}
              />
            </LocalizationProvider>
          </Box>
        </Stack>
      </Popover>
    </React.Fragment>
  );
}

export default StaticDateRange
