import React from 'react'
import { Pie } from 'react-chartjs-2'
import Grid from '@material-ui/core/Grid'

const DetailsDataChart = ({ rows, active }) => {
  if (!rows && !active) {
    return null
  }

  return <Grid container justify='center'>
    <Grid item xs={8}>
      <Pie
        data={rows}
        options={{
          layout: {
            padding: 20
          },
          plugins: {
            legend: {
              labels: {
                generateLabels: (chart) => {
                  const data = chart.data;
                  if (data.labels.length && data.datasets.length) {
                    return data.labels.map((label, i) => {
                      const meta = chart.getDatasetMeta(0);
                      const style = meta.controller.getStyle(i);
                      const value = data.datasets[0].data[i]
                      return {
                        text: `${label} (${value})`,
                        fillStyle: style.backgroundColor,
                        strokeStyle: style.borderColor,
                        lineWidth: style.borderWidth,
                        hidden: !chart.getDataVisibility(i),
                        index: i
                      };
                    });
                  }
                  return [];
                }
              }
            }
          }
        }}
      />
    </Grid>
  </Grid>
}

export default DetailsDataChart
