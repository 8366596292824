import React from 'react'
import { AnalyticsContext } from './context'
import useRawAnalyticsData from '../hooks/use-raw-analytics-data'
import Paper from '../views/paper-box'
import ChartsViewSelector from './charts-view-selector'
import DetailsDataGrid from './details-data-grid'
import DetailsDataChart from './details-data-chart'
import Grid from '@material-ui/core/Grid'
import { Typography } from '@material-ui/core'
import { rowDataToChartData } from '../helpers/filters'
import Totals from './totals'
import NoDataPlaceholder from './no-data-placeholder'
import { format } from 'date-fns'

const CompanyProfile = ({ selectedPartner, active }) => {
  const [view, setView] = React.useState('chart')
  const [pieData, setPieData] = React.useState({})

  const { currentDate } = React.useContext(AnalyticsContext)

  const rawData = useRawAnalyticsData({
    filters: `ga:eventCategory==${selectedPartner};ga:eventAction==Bedrijfsprofiel`,
    metrics: 'ga:totalEvents',
    dimensions: 'ga:eventLabel',
    startDate: format(currentDate[0], 'yyyy-MM-dd'),
    endDate: format(currentDate[1], 'yyyy-MM-dd')
  })

  React.useEffect(() => {
    if (rawData?.rowsDataObject) {
      const rawPieData = rowDataToChartData(rawData?.rowsDataObject, 'Totalen')
      setPieData(rawPieData)
    }
  }, [rawData?.rowsDataObject])


  React.useEffect(() => {
    if (rawData?.execute) {
      rawData.execute()
    }
  }, [currentDate])

  if (!active) {
    return null
  }

  return <>
    <Grid container spacing={3}>
      {
        rawData?.data?.rows?.length > 0
          ? <>
            <Grid item xs={8}>
              <Typography variant='subtitle1'>
                Conversie
              </Typography>
              <Typography variant='caption' gutterBottom>
                Bedrijfspagina
              </Typography>
              <Paper
                paperProps={{
                  variant: 'outlined',
                  square: true
                }}
              >
                <Grid container item xs={12} justify='flex-end'>
                  <ChartsViewSelector
                    setNewView={setView}
                    currentView={view}
                  />
                </Grid>
                <Grid item xs={12}>
                {
                  view === 'chart' && pieData && <DetailsDataChart
                    rows={pieData}
                  />
                }
                {
                  view === 'table' && pieData && <DetailsDataGrid
                    rows={rawData?.rowsDataObject || []}
                  />
                }
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='subtitle1'>
                Totalen
              </Typography>
              <Typography variant='caption' gutterBottom>
                Aantal keer bekeken
              </Typography>
              <Totals totals={rawData?.totals} />
            </Grid>
          </>
        : <NoDataPlaceholder />
      }
    </Grid>
  </>
}

export default CompanyProfile
