import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Grid from '@material-ui/core/Grid'
import { Box, Container } from '@material-ui/core'
import DataTable from './data-table'
// import DateRange from './date-range'
import StaticDateRange from './static-date-range'
import { Typography } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(2)
  }
}))

const Analytics = () => {
  const classes = useStyles()

  return <Container maxWidth='md'>
    <Box
      display='flex'
      justifyContent='space-between'
      alignItems='center'
    >
      <Typography variant='h5'>
        Analytics for Recron.nl
      </Typography>
      <StaticDateRange />
    </Box>
    <DataTable
      title='Weergave per partner'
      metrics='ga:totalEvents'
      dimensions='ga:eventCategory'
    />
  </Container>
}


export default Analytics
