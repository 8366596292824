import React from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/styles'

import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Box from '@material-ui/core/Box'

import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import styled from 'styled-components'

// import DateRange from './date-range'
import StaticDateRange from './static-date-range'
import { convertDataNameToNiceName } from '../helpers/name'

import RestDataView from './rest-data-view'
import CompanyProfile from './company-profile'

const HeadingDiv = styled(Typography)`
  margin-top: 10px;
`

const MarginDiv = styled(Divider)`
  margin-bottom: 10px;
  margin-top: 20px;
`

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: theme.spacing(2)
  }
}))

const TabPanel = ({ children }) => {
  return <Box mt={2}>
    { children }
  </Box>
}


const Details = ({ partner: selectedPartner }) => {
  const [activeTab, setActiveTab] = React.useState(0)
  const classes = useStyles()

  return <>
    <Grid container>
      <Grid item xs={6}>
        <HeadingDiv variant='h6' className={classes.heading}>
          {
            convertDataNameToNiceName(selectedPartner) || selectedPartner
          }
        </HeadingDiv>
      </Grid>
      <Grid container item xs={6} justifyContent='flex-end'>
        <Box mt={1}>
          <StaticDateRange />
        </Box>
      </Grid>
    </Grid>
    <MarginDiv />
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Tabs
          value={activeTab}
          onChange={(e, newValue) => setActiveTab(newValue)}
          indicatorColor='primary'
          textColor='primary'
        >
          <Tab label='Bedrijfsprofiel' />
          <Tab label='Overige paginas' />
        </Tabs>

        <TabPanel>
          <CompanyProfile selectedPartner={selectedPartner} active={activeTab === 0} />
        </TabPanel>
        <TabPanel>
          <RestDataView selectedPartner={selectedPartner} active={activeTab === 1} />
        </TabPanel>
      </Grid>
    </Grid>
  </>
}

Details.propTypes = {
  partner: PropTypes.string.isRequired
}

export default Details
