import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Paper from '@material-ui/core/Paper'
import { Typography } from '@material-ui/core'

const TotalsTypo = styled(Typography)`
  text-align: center;
  margin: 10px auto 10px;
`

const Totals = ({ totals = 0 }) => {
  return <Paper variant='outlined' square>
    <TotalsTypo variant='h3'>{totals}</TotalsTypo>
  </Paper>
}

Totals.propTypes = {
  totals: PropTypes.number
}

export default Totals

