import React from 'react'
import Grid from '@material-ui/core/Grid'
import Details from './details'

const SingleDetailView = ({ partner }) => <Grid container spacing={3}>
  <Grid item xs={12}>
    <Details partner={partner} />
  </Grid>
</Grid>

export default SingleDetailView
