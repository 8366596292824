import React from 'react'
import { useAnalyticsApi, useAuthorize } from 'react-use-analytics-api'
import Grid from '@material-ui/core/Grid'
import { subDays } from 'date-fns'

export const AnalyticsContext = React.createContext()

const Context = ({ children, token }) => {
  const { ready, gapi, authorized, error } = useAnalyticsApi()
  const [currentDate, setCurrentDate] = React.useState([
    subDays(new Date().setHours(0,0,0,0), 7),
    new Date().setHours(0,0,0,0)
  ])

  const { access_token } = token

  const authorize = useAuthorize(gapi, {
    serverAuth: {
      access_token: access_token
    }
  })

  React.useEffect(() => {
    if (ready && !error) {
      authorize()
    }
  }, [ready])

  if (error) {
    return JSON.stringify(error)
  }

  return <AnalyticsContext.Provider
    value={{
      gapi,
      ready,
      viewId: 'ga:243045730', // recron.nl -> all website data
      currentDate,
      setCurrentDate,
      authorized,
      error
    }}
  >
    {
      authorized
        ? children
        : <Grid container item xs={8} justify='center'>
          <div id='authorize-container-id' />
        </Grid>
    }
  </AnalyticsContext.Provider>
}

export default Context

