import React from 'react'
import PropTypes from 'prop-types'
import { AnalyticsContext } from './context'
import { useData } from 'react-use-analytics-api'
import { DataGrid } from '@material-ui/data-grid'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import DataModal from './data-modal'
import Details from './details'
import { format } from 'date-fns'

const columns = [
  {
    field: 'name',
    headerName: 'Partner',
    flex: 1,
    filterable: false
  },
  {
    field: 'views',
    headerName: 'Views',
    flex: .5,
    filterable: false
  }
]

/*
  metrics='ga:totalEvents'
  dimensions='ga:eventCategory'
*/

const DataTable = ({ metrics, dimensions, title }) => {
  const { gapi, viewId, currentDate } = React.useContext(AnalyticsContext)
  const [rows, setRows] = React.useState()
  const [selectedPartner, setSelectedPartner] = React.useState(false)
  const [filter, setFilter] = React.useState('')

  const query = {
    metrics: metrics,
    dimensions: dimensions,
    'start-date': format(currentDate[0], 'yyyy-MM-dd'),
    'end-date': format(currentDate[1], 'yyyy-MM-dd'),
    ids: viewId,
  }

  const execute = useData(gapi, query, (response) => {
    const { rows } = response
    if (rows && rows.length > 0) {
      const filteredResponse = rows
        .map((respData, index) => {
          const splitName = respData[0].split(' - ')

          return {
            id: index,
            itemIndex: splitName[0],
            name: respData[0],
            fullName: respData[0],
            views: Number(respData[1])
          }
        })

      setRows(filteredResponse)
    }
  })

  const onRowClick = (param) => {
    const { row: { fullName } } = param
    setSelectedPartner(fullName)
  }

  const closeModal = () => setSelectedPartner(null)

  React.useEffect(() => {
    execute()
  }, [execute, currentDate])

  if (!rows) {
    return null
  }

  const filteredRows = rows.filter(row => {
    const lowerName = row.name.toLowerCase()
    const lowerfilter = filter.toLowerCase()

    if (lowerfilter.length > 0) {
      return lowerName.includes(lowerfilter)
    }

    return true
  })

  return <div>
    <Box mb={2} display='flex' alignItems='center'>
      <TextField
        onChange={(e) => setFilter(e.target.value)}
        label='Zoeken'
        variant='outlined'
        size='small'
        value={filter}
      />
      {
        filter.length > 0  && <a style={{ marginLeft: 10 }} onClick={() => setFilter('')}>Wissen</a>
      }
    </Box>
    <DataGrid
      rows={filteredRows}
      columns={columns}
      pageSize={10}
      autoHeight
      rowsPerPageOptions={[10, 20, 50]}
      disableSelectionOnClick
      onRowClick={onRowClick}
      disableColumnFilter
      disableColumnMenu
      sortModel={[
        {
          field: 'views',
          sort: 'desc'
        }
      ]}
    />
    {
      selectedPartner &&
        <DataModal
          selectedPartner={selectedPartner}
          closeModal={closeModal}
        >
          <Details
            partner={selectedPartner}
          />
        </DataModal>
    }
  </div>
}

DataTable.propTypes = {
  metrics: PropTypes.string.isRequired,
  dimensions: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default DataTable
